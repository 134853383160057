<template>
  <div>
    <el-card>
      <div class='head table_border'>
        <span style='width: 100%;text-align: center'>
          <el-checkbox v-if='!!form.id' @change='handleCheckStatus' style='float: left;padding: 0 10px' size='medium'
                       v-model='checked'> 核算完成 </el-checkbox>
          <el-link @click='handleGetInfo()'>{{ form.group_name || '-/-' }}：{{ form.leader_name || ''
            }} </el-link>
          <el-button v-if='canEdit' type='primary' :disabled='loading' :loading='loading' size='mini'
                     :icon='isEdit?"el-icon-edit":""'
                     @click='handleEdit' style='float: right;margin-right: 10px'>{{
              isEdit ? '保存' : '编辑'
            }}</el-button>
        </span>
      </div>
      <el-row class='table_border table_row'>
        <div>
          Q3季度基准营收目标：
        </div>
        <div>
          <el-input style='width: 400px;min-width: 200px;max-height: 500px' v-if='isEdit' v-model='form.remark'
                    placeholder='备注信息必填，例如 实际发放率为最终金额的50%'
                    clearable></el-input>
          <div v-else>
             <span class='money-CNY'
                   style='color: red;font-weight: bold;font-size: 1.2em'>
            {{ $utils.numberFormat(form.flag_fee, 2) }}
          </span>
<!--            (<span class='money' style='font-size: 0.9em'>{{$utils.numberFormat(sumFlagVal,2)}}</span>)-->
            <span>（备注：{{ form.remark || '-' }}）</span>
          </div>

        </div>

      </el-row>
      <el-row class='table_border table_row'>
        <div>
          总监季度奖金包为：
        </div>
        <div>
          <el-input style='width: 400px;min-width: 200px;max-height: 500px' v-if='isEdit' v-model='form.bonus_package'
                    placeholder='请输入总监季度奖金包，例如 月薪*3'
                    clearable></el-input>
          <span v-else>{{ form.bonus_package }}</span>
        </div>
      </el-row>
      <div v-if='form.standard&&form.standard.length>0'>
        <el-table :data='form.standard' border>
          <el-table-column label='考核标准' min-width='420px'>
            <template slot='header'>
              <span class='table-title'>考核标准</span>
            </template>
            <template slot-scope='{row,$index}'>
              <div>
                <div style='display: flex;flex-direction: row'>
                  <div v-if='$index!==0'>
                    <el-input v-model='row.gt' placeholder='输入营收目标，单位：元（人民币）' style='display: inline-block;width: 80%;'
                              v-if='isEdit' clearable></el-input>
                    <span class='money-CNY standard-fee'
                          v-else>{{ row.gt ? $utils.numberFormat(row.gt, 2) : '/' }}</span>
                  </div>
                  <div style='text-align: center;'>
                    <span style='margin: auto;font-size: 18px;text-align: center;'>
                      <span style='display: inline-block;font-size: 16px;margin-right: 10px;'>
                       <span v-if='$index!==0'>&le;</span><span v-else>&nbsp;&nbsp;</span>
                      </span>
                      {{ row.title || '-' }}
                       <span style='display: inline-block;font-size: 16px;margin-left: 10px'>
                        <span v-if='$index!==form.standard.length-1'>&lt;</span>
                         <span v-else>&nbsp;&nbsp;</span>
                       </span>
                    </span>
                  </div>
                  <div style='margin-left: 10px'>
                    <div v-if='$index!==form.standard.length-1'>
                      <el-input v-model='row.lt' placeholder='输入营收目标，单位：元（人民币）'
                                style='display: inline-block;width: 80%;'
                                v-if='isEdit'
                                clearable></el-input>
                      <span class='money-CNY standard-fee' style='display: inline-block;width: 80%;text-align: left;'
                            v-else>{{ row.lt ? $utils.numberFormat(row.lt, 2) : '/' }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label='奖金包发放比例' prop='ratio' min-width='140px' header-align='center' align='center'>
            <template slot='header'>
              <span class='table-title' style='text-align: center'>奖金包发放比例</span>
            </template>
            <template slot-scope='{row}'>
              <el-input v-if='isEdit' v-model='row.ratio' placeholder='输入奖金包发放比例'
                        style='display: inline-block;width: 80%'
                        clearable></el-input>
              <span style='display: inline-block;width: 80%;min-width:120px;text-align: right;' v-else>{{ row.ratio
                }}</span>
              <span class='ratio'>&nbsp; &nbsp;</span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-row class='table_border'>
        <el-col :span='6'>
          <div class='inputBox'>
            <span style='margin: 5px;padding: 0;font-size: 1em;font-weight: bold'>实际完成</span>
          </div>
        </el-col>
        <el-col :span='6'>
          <div class='inputBox'>
            <el-input style='width: 80%' class='input' v-if='isEdit' v-model='form.actually'
                      placeholder='请输入实际完成' clearable></el-input>
            <span class='money-CNY' v-else>
              {{$utils.numberFormat(form.actually,2)}}
            </span>
          </div>
        </el-col>
        <el-col :span='6'>
          <div class='inputBox'>
            <span style='margin: 5px;padding: 0;font-size: 1em;font-weight: bold'>奖金发放比例</span>
          </div>
        </el-col>
        <el-col :span='6'>
          <div class='inputBox'>
            <el-input style='width: 80%'
                      v-if='!isEdit' v-model='form.payout_ratio'
                      placeholder='请输入奖金发放比例(%)' clearable>
            </el-input>
            <span v-else class='ratio'>{{ form.payout_ratio || '-' }}</span>
          </div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import EditableCell from '@/components/EditableCell'

export default {
  name: 'EditPaChief',
  components: { EditableCell },
  props: {
    sumFlagVal: {
      type: [Number],
      default() {
        return 0
      }
    },
    sumRealVal: {
      type: [Number],
      default() {
        return 0
      }
    }
  },
  data() {
    return {
      checked: false,
      isEdit: false,
      total_income: 3900000,
      form: {
        'group_id': null,
        'group_name': null,
        'leader_id': null,
        'leader_name': null,
        'year': null,
        'quarter': null,
        remark: null,
        bonus_package: null,
        flag_fee: null,
        actually: null,
        payout_ratio: null,
        //考核标准
        standard: [
          { title: '季度总营收', gt: null, lt: 0, ratio: null },
          { title: '季度总营收', gt: 0, lt: 0, ratio: null },
          { title: '季度总营收', gt: 0, lt: 0, ratio: null },
          { title: '季度总营收', gt: 0, lt: 0, ratio: null },
          { title: '季度总营收', gt: 0, lt: 0, ratio: null },
          { title: '季度总营收', gt: 0, lt: 0, ratio: null },
          { title: '季度总营收', gt: 0, ratio: 0 }
        ]
        // num_1: null,
        // num_2_1: null,
        // num_2_2: null,
        // num_3_1: null,
        // num_3_2: null,
        // num_4_1: null,
        // num_4_2: null,
        // num_5_1: null,
        // num_5_2: null,
        // num_6_1: null,
        // num_6_2: null,
        // num_7: null,
        // ratio_1: null,
        // ratio_2: null,
        // ratio_3: null,
        // ratio_4: null,
        // ratio_5: null,
        // ratio_6: null,
        // ratio_7: null,

      },
      ratioData: null,
      loading: false,
      canEdit: true,
      searchCondition: {
        group_id: '',
        year: '',
        quarter: ''
      }
    }
  },
  methods: {
    show(searchCondition) {
      this.isEdit = false
      this.searchCondition = searchCondition
      this.handleGetInfo()
    },
    handleEdit() {
      if (this.isEdit)
        this.handleSave()

      this.isEdit = !this.isEdit
    },
    async handleSave() {
      await this.$api.savePaChief(this.form)
      this.$notify.success('已保存')
      this.isEdit = false
      await this.handleGetInfo()
    },
    async handleCheckStatus() {
      if (this.checked === true) {
        if (!this.form.payout_ratio) {
          this.$notify.warning('核算完成，发放奖金比例必填')
          return false
        }
      }
      await this.$api.checkPaChiefStatus({
        id: this.form.id,
        check_status: this.checked ? 'Y' : 'N',
        payout_ratio: this.form.payout_ratio
      })
    },
    async handleGetInfo() {
      //总监绩效-部门
      if (this.searchCondition.year && this.searchCondition.quarter && this.searchCondition.dept_id) {
        let { info } = await this.$api.getPaChief({
          year: this.searchCondition.year,
          quarter: this.searchCondition.quarter,
          group_id: this.searchCondition.dept_id
        })
        //
        // if (info.check_status !== 'Y') {
        //   //未核算完整，使用实时计算的
        //   info.actually = this.sumRealVal
        // }

        this.form = { ...info }
        this.checked = info.check_status === 'Y'
        // this.form.standard = info.standard || []
        // this.form.group_id = info.group_id || null
        // this.form.group_name = info.group_name || null
        // this.form.bonus_package = info.bonus_package || null
        // this.form.remark = info.remark || null
        // this.form.payout_ratio = info.payout_ratio || null
      }

    }
  }
}
</script>

<style lang='scss' scoped>
.head {
  height: 40px;
  background-color: rgb(244, 244, 245);
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  //border: 2px solid #DCDFE6;
  padding: 10px 0;
}

.table_border {
  border: 1px solid #DCDFE6;
  min-height: 30px;
  line-height: 30px;
  font-size: 18px;
}

.table_row {
  padding: 10px;
  display: flex;
  flex-direction: row;
}

.title {
  padding: 10px;
  background-color: rgb(244, 244, 245);
  font-size: 20px;
  border-right: 1px solid #DCDFE6;
}

.table-title {
  font-size: 20px;
  font-weight: bold;
}

.content {
  border: 1px solid #DCDFE6;
  border-bottom: none;
  padding: 10px;

  > span {
    margin: 0 10px;
  }
}

.bonus {
  border: none;

  .content {
    border-left: none;
    border-right: none;
  }
}

.inputBox {
  height: 30px;
  line-height: 30px;
  padding: 5px 10px;
  font-size: 20px;
  border-right: 1px solid #DCDFE6;
  //text-align: center;
}


.standard-fee {
  width: 80%;
  min-width: 120px;
  text-align: right;
  margin-left: 10px;
  margin-right: 10px;
}


</style>
